<template>
  <div class="jwl-gradebook-student-unit">
    <div class="jwl-gradebook-student-unit__summary" v-if="hasOnline">
      <span v-t="'gradebook.onlinePoints'" />
      <span v-if="unitScores.online.max > 0">
        {{unitScores.online.percent}}%
      </span>
    </div>
    <div class="jwl-gradebook-student-unit__summary" v-if="hasOnsite">
      <span v-t="'gradebook.onsitePoints'" />
      <span v-if="unitScores.onSite.max > 0">
        {{unitScores.onSite.percent}}%
      </span>
    </div>
    <div class="jwl-gradebook-student-unit__summary" v-if="hasAttendance">
      <span v-t="'gradebook.attendance'" />
      <span v-if="unitScores.attendances.max > 0">
        {{unitScores.attendances.percent}}%
      </span>
    </div>

    <div class="jwl-gradebook-student-unit__grades">
      <div class="jwl-gradebook-student-unit__grade-container" v-for="task in linkTasks" :key="`task-${lmsId}-${jwlUnit.id}-${task.id}`">
        <router-link class="jwl-gradebook-student-unit__grade"
          v-if="taskResponsibility(task) === ''"
          :key="`task-${task.id}`"
          :to="taskRouterLink(task)">
          <div class="jwl-gradebook-student-unit__icon" :class="[taskState(task), taskResponsibility(task), focusedTask(task)]">
            <task-icon :task-type="task.type" :use-duotone="taskIconState(task)" />
          </div>

          <template v-if="task.type !== 'attendance' && task.maxScore > 0">
            {{studentGradeScore(task)}} / {{task.maxScore}}
          </template>
        </router-link>

        <div class="jwl-gradebook-student-unit__grade" v-else :key="`task-${task.id}`">
          <div class="jwl-gradebook-student-unit__icon" :class="[taskState(task), taskResponsibility(task)]">
            <task-icon :task-type="task.type" :use-duotone="taskIconState(task)" />
          </div>

          {{studentGradeScore(task)}} / {{task.maxScore}}
        </div>
      </div>
    </div>

    <div class="jwl-gradebook-student-unit__publish">
      <jwl-submit-row
        class="jwl-gradebook-student-unit__submit-row"
        v-if="tasksStatus === 'publishable'"
        form-style="full-width"
        :form-submit-status="networkStatus"
        @on-click="publishStudentUnit">
        <template v-slot:default>
          {{$t('facilitatorGradebook.publishStudentUnit')}}
        </template>
        <template v-slot:error>
          {{error.trans ? $t(error.message) : error.message}}
        </template>
      </jwl-submit-row>
      <div
        v-else
        class="jwl-gradebook-student-unit__publish-status"
        v-t="`facilitatorGradebook.status.${tasksStatus}`" />
    </div>
  </div>
</template>

<script>
import JwlSubmitRow from '@/components/JwlSubmitRow.vue';

const TaskIcon = () => import('@/components/TaskIcon.vue');

const STATUS_ERROR = -1;
const STATUS_INITIAL = 0;
const STATUS_LOADING = 1;
const STATUS_SUCCESS = 2;

export default {
  name: 'jwl-gradebook-student-unit',
  props: {
    hasOnline: Boolean,
    hasOnsite: Boolean,
    hasAttendance: Boolean,
    lmsId: String,
    jwlCourse: Object,
    gradebookCourse: Object,
    jwlUnit: Object,
    studentGrades: Object,
    globalClassroomId: Number,
  },
  components: {
    JwlSubmitRow,
    TaskIcon,
  },
  data () {
    return {
      grades: this.studentGrades,
      networkStatus: STATUS_INITIAL,
      error: null,
    };
  },
  methods: {
    taskResponsibility (task) {
      const alwaysResponsible = ['online_facilitator', 'onsite_facilitator'].includes(this.gradebookCourse.role)
        && ['online-onsite_facilitator', 'onsite-online_facilitator'].includes(task.responsibility);
      return alwaysResponsible || task.responsibility === this.gradebookCourse.role || this.gradebookCourse.role === 'admin'
        ? ''
        : 'jwl-gradebook-student-unit__icon--other';
    },
    taskState (task) {
      const baseClass = 'jwl-gradebook-student-unit__icon';
      if (!this.grades) {
        if (['track', 'attendance'].includes(task.type)) {
          const deadline = new Date(task.studentDeadline);
          const now = new Date();
          if (deadline < now) {
            return `${baseClass}--ungraded`;
          }
        }

        return `${baseClass}--nothing`;
      }

      const submitted = this.grades[task.id];

      if (submitted) {
        const hasPublished = !!submitted.publishedAt;
        const isHasPublishTask = task.type === 'submit_text' || task.type === 'submit_work';
        const isAttendanceTask = task.type === 'attendance';
        if (!isHasPublishTask || (isHasPublishTask && hasPublished)) {
          if (submitted.grade && (submitted.grade.score >= 0 || submitted.grade.attended !== null)) {
            if (submitted.grade.date) {
              if ((isAttendanceTask && !submitted.grade.attended) || (task.maxScore > 0 && submitted.grade.score === 0)) {
                return `${baseClass}--0-grade`;
              }
              return `${baseClass}--grade`;
            }
            return `${baseClass}--unpublished`;
          }
          return `${baseClass}--ungraded`;
        }
        return `${baseClass}--not-published`;
      }

      if (['track', 'attendance'].includes(task.type)) {
        const deadline = new Date(task.studentDeadline);
        const now = new Date();
        if (deadline < now) {
          return `${baseClass}--ungraded`;
        }
      }

      return `${baseClass}--nothing`;
    },
    focusedTask (task) {
      const { focusTask, focusUser } = this.$route.query;
      return {
        'jwl-gradebook-student-unit__icon--focused': focusTask === task.code && focusUser === this.lmsId,
      };
    },
    publishStudentUnit () {
      if (this.networkStatus !== STATUS_LOADING) {
        this.networkStatus = STATUS_LOADING;
        this.$store.dispatch('publishGrade', {
          concentrationCode: this.$route.params.concentration,
          unitCode: this.jwlUnit.code,
          lmsId: this.lmsId,
        }).then((data) => {
          this.grades = data;
          this.networkStatus = STATUS_SUCCESS;
          setTimeout(() => {
            this.networkStatus = STATUS_INITIAL;
          }, 2500);
        }).catch((e) => {
          this.error = e;
          this.networkStatus = STATUS_ERROR;
        });
      }
    },
    studentGradeScore (task) {
      if (!this.grades) {
        return '-';
      }

      const submitted = this.grades[task.id];
      if (submitted) {
        const hasGrade = submitted.grade && submitted.grade.score >= 0;

        return hasGrade
          ? submitted.grade.score
          : '-';
      }
      return '-';
    },
    taskIconState (task) {
      if (!this.grades) {
        return false;
      }

      const submitted = this.grades[task.id];
      if (submitted && submitted.id) {
        if (task.type === 'track') {
          return submitted.grade && submitted.grade.score >= 0;
        }
        if (task.type === 'attendance') {
          return submitted.grade && submitted.grade.attended !== null;
        }
        return true;
      }
      return false;
    },

    taskRouterLink (task) {
      if (task.type === 'discuss') {
        return {
          name: 'grade_discussion',
          params: {
            classroomId: this.globalClassroomId,
            task: task.code,
            course: this.jwlCourse.code,
          },
        };
      }

      return { name: 'grade', params: { task: task.code, lmsId: this.lmsId, course: this.jwlCourse.code } };
    },
  },
  computed: {
    unitScores () {
      let maxOnline = 0;
      let achievedOnline = 0;
      let maxOnSite = 0;
      let achievedOnSite = 0;
      let maxAttendance = 0;
      let achievedAttendance = 0;
      this.jwlUnit.tasks.forEach((task) => {
        const isOnline = task.facilitatorResponsibility === 'online_facilitator' || task.facilitatorResponsibility === 'online-onsite_facilitator';
        const isOnSite = task.facilitatorResponsibility === 'onsite_facilitator' || task.facilitatorResponsibility === 'onsite-online_facilitator';
        if (task.type === 'attendance') {
          maxAttendance += 1;
          if (this.grades) {
            const submitted = this.grades[task.id];
            if (submitted && submitted.grade && submitted.grade.attended) {
              achievedAttendance += 1;
            }
          }
        } else if (isOnline) {
          maxOnline += task.maxScore;
          if (this.grades) {
            const submitted = this.grades[task.id];
            if (submitted && submitted.grade && submitted.grade.score >= 0) {
              achievedOnline += submitted.grade.score;
            }
          }
        } else if (isOnSite) {
          maxOnSite += task.maxScore;
          if (this.grades) {
            const submitted = this.grades[task.id];
            if (submitted && submitted.grade && submitted.grade.score >= 0) {
              achievedOnSite += submitted.grade.score;
            }
          }
        }
      });
      let onlinePercent = null;
      let onsitePercent = null;
      let attendancePercent = 0;
      if (maxOnline > 0) {
        const percent = (achievedOnline / maxOnline) * 100;
        onlinePercent = Math.round(percent);
      }
      if (maxOnSite > 0) {
        const percent = (achievedOnSite / maxOnSite) * 100;
        onsitePercent = Math.round(percent);
      }
      if (maxAttendance > 0) {
        const percent = (achievedAttendance / maxAttendance) * 100;
        attendancePercent = Math.round(percent);
      }

      return {
        online: {
          max: maxOnline,
          achieved: achievedOnline,
          percent: onlinePercent,
        },
        onSite: {
          max: maxOnSite,
          achieved: achievedOnSite,
          percent: onsitePercent,
        },
        attendances: {
          max: maxAttendance,
          achieved: achievedAttendance,
          percent: attendancePercent,
        },
      };
    },
    tasksStatus () {
      let deadlineNotPassed = false;
      const nonGradedTasks = [];
      const nonPublishedTasks = [];
      const now = new Date();
      this.jwlUnit.tasks.forEach((task) => {
        const isOnline = this.gradebookCourse.role === 'online_facilitator'
          && (task.responsibility === 'online_facilitator'
            || task.responsibility === 'online-onsite_facilitator');
        const isOnSite = this.gradebookCourse.role === 'onsite_facilitator'
          && (task.responsibility === 'onsite_facilitator'
            || task.responsibility === 'onsite-online_facilitator');
        if (isOnline || isOnSite) {
          const studentDeadline = new Date(task.studentDeadline);
          if (studentDeadline > now) {
            deadlineNotPassed = true;
          }

          const submitted = this.grades ? this.grades[task.id] : null;

          if (submitted) {
            if (submitted.grade && !submitted.grade.date) {
              nonPublishedTasks.push(task);
            }
            if (!submitted.grade) {
              nonGradedTasks.push(task);
            }
          } else if (['track', 'attendance'].includes(task.type)) {
            const deadline = new Date(task.studentDeadline);
            if (deadline < now) {
              nonGradedTasks.push(task);
            } else {
              nonPublishedTasks.push(task);
            }
          } else {
            nonPublishedTasks.push(task);
          }
        }
      });

      if (deadlineNotPassed) {
        return 'deadlineNotPassed';
      }

      if (nonGradedTasks.length > 0) {
        return 'nonGradedTasksPresent';
      }

      if (nonPublishedTasks.length > 0) {
        return 'publishable';
      }

      return 'published';
    },
    linkTasks () {
      return this.jwlUnit.tasks.filter((task) => task.type !== 'survey');
    },
  },
  watch: {
    studentGrades (newData) {
      Object.assign(this.grades, newData);
    },
  },
  mounted () {
    const { focusUser, focusTask } = this.$route.query;
    const index = this.jwlUnit.tasks.findIndex((task) => task.code === focusTask);
    if (index >= 0 && this.lmsId === focusUser) {
      this.$el.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  },
};
</script>

<style lang="scss">
@use "~common-modules/src/styles/mixins";
@use "~common-modules/src/styles/variables";

@keyframes pulse {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: .25;
  }
}

.jwl-gradebook-student-unit {
  align-items: flex-start;
  box-sizing: border-box;
  display: flex;
  flex: 0 0 25%;
  flex-flow: column;
  justify-content: flex-start;
  padding: .25rem .2rem;

  @include mixins.mq(variables.$mq-tablet) {
    flex-basis: 100%;
  }

  &__summary {
    align-items: baseline;
    background-color: var(--color-gray-100);
    border-radius: 3px;
    box-sizing: border-box;
    display: flex;
    flex: 0 0 2.5rem;
    justify-content: space-between;
    margin-bottom: .5rem;
    padding: .5rem .75rem;
    width: 100%;
  }

  &__grades {
    align-items: flex-start;
    display: grid;
    flex-flow: row wrap;
    grid-auto-rows: 4.5rem;
    grid-gap: .4rem;
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: flex-start;
    width: 100%;
  }

  &__grade-container {
    align-items: center;
    display: grid;
    grid-template: "icon" auto "points" auto / 100%;
  }

  &__grade {
    align-items: center;
    color: var(--color-black);
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    margin-bottom: .4rem;
    text-decoration: none;

    a#{&} {
      cursor: pointer;
    }
  }

  &__icon {
    --status-main: var(--color-gray-100);
    --status-color: var(--color-grey);
    --responsibility-background: var(--status-main);
    --responsibility-border: var(--status-main);
    --responsibility-color: var(--status-color);

    align-items: center;
    background-color: var(--responsibility-background);
    border: .1rem solid var(--responsibility-border);
    border-radius: 50%;
    box-sizing: border-box;
    color: var(--responsibility-color);
    display: flex;
    font-size: 1.5rem;
    height: 3rem;
    justify-content: center;
    width: 3rem;

    &--0-grade {
      --status-main: var(--color-error);
      --status-color: var(--color-white);
    }

    &--grade {
      --status-main: var(--color-success);
      --status-color: var(--color-white);
    }

    &--unpublished {
      --status-main: var(--color-warning);
      --status-color: var(--color-white);
    }

    &--not-published {
      --status-main: var(--color-gray-100);
      --status-color: var(--color-warning);
    }

    &--ungraded {
      --status-main: var(--color-grey);
      --status-color: var(--color-white);
    }

    &--other {
      --responsibility-background: var(--status-color);
      --responsibility-color: var(--status-main);
    }

    &--nothing#{&}--other {
      --responsibility-background: transparent;
      --responsibility-border: var(--opaque-grey);
      --responsibility-color: var(--dark-grey);
    }

    &--focused {
      animation: 1s 7 ease-in-out pulse;
    }
  }

  &__publish {
    align-items: stretch;
    display: flex;
    justify-content: stretch;
    margin-top: auto;
    width: 100%;
  }

  &__submit-row {
    margin-bottom: 0 !important;
  }

  &__publish-status {
    box-sizing: border-box;
    color: var(--color-grey);
    font-size: .85rem;
    font-style: italic;
    padding: 0 .5rem;
  }
}
</style>
