























































































































import CommonIcon from 'common-modules/src/components/CommonIcon.vue';
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  components: {
    CommonIcon,
  },
})
export default class JwlFacilitatorGradebookLegend extends Vue {
}
