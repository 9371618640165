



















































































































































import CommonIcon from 'common-modules/src/components/CommonIcon.vue';
import Vue from 'vue';
import { Prop, Watch } from 'vue-property-decorator';
import { RunCourse } from 'common-modules/src/store/interface/RunCourse';
import Component from 'vue-class-component';
import jwlFacilitatorGradebookLegend from './jwlFacilitatorGradebookLegend.vue';
import jwlGradebookStudentUnit from './jwlGradebookStudentUnit.vue';
import jwlToggle from './jwlToggle.vue';
import {
  GradebookClassroom,
  GradebookCourse,
  GradebookUser,
  GradingSchemaStep,
} from '@/store/interface/Gradebook';
import { ExtendedRunUnit, RunUnit } from '@/store/interface/Unit';

const IconArrowLeft = () => import('common-modules/src/assets/fa-icons/duotone/arrow-left.svg');
const IconArrowRight = () => import('common-modules/src/assets/fa-icons/duotone/arrow-right.svg');
const CommonError = () => import('common-modules/src/components/CommonError.vue');

@Component({
  components: {
    CommonError,
    jwlFacilitatorGradebookLegend,
    jwlGradebookStudentUnit,
    CommonIcon,
    jwlToggle,
    IconArrowLeft,
    IconArrowRight,
  },
})
export default class JwlFacilitatorGradebook extends Vue {
  $refs!: {
    jwlSticky: HTMLElement;
  }

  @Prop(Boolean)
  hasOnline!: boolean;

  @Prop(Boolean)
  hasOnsite!: boolean;

  @Prop(Object)
  runCourse!: RunCourse;

  @Prop(Object)
  gradebookCourse!: GradebookCourse;

  @Prop(Object)
  activeClassroom!: GradebookClassroom;

  @Prop(Array)
  gradingSchema!: GradingSchemaStep[];

  loaded = false;
  grades: any = null;
  gradebook = null;
  activeIndex = 0;
  visibleSlides = 3;
  error = null;
  observer = null;
  header: HTMLElement|null = null;
  mobileOpenStudents: string[] = [];

  @Watch('activeClassroom')
  fetchData (): void {
    this.loaded = false;
    this.error = null;
    const courseCode = this.runCourse.code;
    const concentrationCode = this.$route.params.concentration;
    const classroomId = this.activeClassroom.id;
    this.$store.dispatch('getData', `gradebook/${concentrationCode}/course/${courseCode}/${classroomId}`)
      .then((data) => {
        this.grades = data;
        this.loaded = true;

        const { focusTask } = this.$route.query;
        if (focusTask) {
          this.$nextTick(() => {
            const index = this.grades.units.findIndex((unit: any) => {
              const contained = unit.tasks.find((task: any) => task.code === focusTask);
              return !!contained;
            });
            if (index >= 0) {
              if (index + this.visibleSlides < this.grades.units.length) {
                this.activeIndex = index;
              } else {
                this.activeIndex = this.grades.units.length - this.visibleSlides;
              }
            }
          });
        }
        this.$nextTick(() => {
          this.initScrollListener();
        });
      })
      .catch((e) => {
        this.error = e;
      });
  }

  unitClass (unit: RunUnit): Record<string, boolean> {
    const now = new Date();
    const startDate = new Date(unit.startDate);
    const endDate = new Date(unit.endDate);
    return {
      'jwl-facilitator-gradebook__header-col--active': startDate <= now && endDate >= now,
    };
  }

  formattedDate (dateString: string): string {
    const date = new Date(dateString);
    return date.toLocaleDateString();
  }

  formattedTime (dateString: string): string {
    const date = new Date(dateString);
    return date.toLocaleTimeString();
  }

  getStudentGrades (lmsId: string): number {
    let score = 0;
    const submissions = this.grades.submissions[lmsId];
    if (submissions) {
      Object.values(submissions).forEach((submission: any) => {
        if (submission.grade) {
          score += submission.grade.score;
        }
      });
    }
    return score;
  }

  getStudentAttendance (lmsId: string): number {
    let score = 0;
    const submissions = this.grades.submissions[lmsId];
    if (submissions) {
      Object.values(submissions).forEach((submission: any) => {
        if (submission.grade?.attended) {
          score += 1;
        }
      });
    }
    return score;
  }

  formattedScore (lmsId: string): string {
    const achievedScore = this.getStudentGrades(lmsId);
    const score = (achievedScore / this.maxScore) * 100;
    return score.toFixed(1);
  }

  formattedAttendance (lmsId: string): string {
    const achievedScore = this.getStudentAttendance(lmsId);
    const score = (achievedScore / this.countAttendance) * 100;
    return score.toFixed(1);
  }

  getLetterGrade (lmsId: string): string|null {
    if (!this.gradingSchema) {
      return null;
    }
    const achievedScore = Number(this.getStudentGrades(lmsId));
    const score = (achievedScore / this.maxScore) * 100;
    const letterStep = this.gradingSchema.find((step) => step.lowerLimitRange <= score);
    if (letterStep) {
      return letterStep.letter;
    }
    return '-';
  }

  getUnitStudentScore (unit: ExtendedRunUnit, lmsId: string, forOnline = true): number {
    const submissions = this.grades.submissions[lmsId];
    let max = 0;
    let achieved = 0;
    unit.tasks.forEach((task) => {
      const includeBecauseOnline = (forOnline && (task.facilitatorResponsibility === 'online_facilitator' || task.facilitatorResponsibility === 'online-onsite_facilitator'));
      const includeBecauseOnsite = (!forOnline && (task.facilitatorResponsibility === 'onsite_facilitator' || task.facilitatorResponsibility === 'onsite-online_facilitator'));
      if (includeBecauseOnline || includeBecauseOnsite) {
        max += task.maxScore;
        if (submissions[task.id] && submissions[task.id].grade) {
          const { grade } = submissions[task.id];
          achieved += grade.score;
        }
      }
    });
    if (max > 0) {
      const percent = (achieved / max) * 100;
      return Math.round(percent);
    }
    return 0;
  }

  goToPrevious (): void {
    if (this.activeIndex - this.visibleSlides >= 0) {
      if ((this.activeIndex % this.visibleSlides) > 0) {
        this.activeIndex -= (this.activeIndex % this.visibleSlides);
      } else {
        this.activeIndex -= this.visibleSlides;
      }
    } else {
      this.activeIndex = 0;
    }
  }

  goToNext (): void {
    if ((this.activeIndex + (this.visibleSlides * 2)) < this.grades.units.length) {
      this.activeIndex += this.visibleSlides;
    } else {
      this.activeIndex = this.grades.units.length - this.visibleSlides;
    }
  }

  scrollListener (): void {
    if (!this.header) {
      this.header = document.querySelector('header');
    }

    const container = this.$refs.jwlSticky;
    if (container && this.header) {
      const { header } = this;
      const isAbove = header.getBoundingClientRect().bottom >= container.getBoundingClientRect().top;
      const isBelow = header.getBoundingClientRect().bottom > (container.getBoundingClientRect().bottom - 250);
      if (isAbove && !isBelow) {
        container.classList.add('jwl-sticky--stick');
      } else {
        container.classList.remove('jwl-sticky--stick');
      }
    }
  }

  resizeHandler (): void {
    const width = window.innerWidth;
    if (width <= 800) {
      this.visibleSlides = 1;
    } else {
      this.visibleSlides = 3;
    }
  }

  initScrollListener (): void {
    window.addEventListener('scroll', this.scrollListener, {
      passive: true,
    });
  }

  destroyScrollListener (): void {
    window.removeEventListener('scroll', this.scrollListener);
  }

  toggleStudent (lmsId: string): void {
    if (this.mobileOpenStudents.includes(lmsId)) {
      const index = this.mobileOpenStudents.indexOf(lmsId);
      this.mobileOpenStudents.splice(index, 1);
    } else {
      this.mobileOpenStudents.push(lmsId);
    }
  }

  mobileStudentOpen (lmsId: string): Record<string, boolean> {
    return {
      'jwl-facilitator-gradebook__data-row--open': this.mobileOpenStudents.includes(lmsId),
    };
  }

  get hasNextSlide (): Record<string, boolean> {
    const hasNext = (this.activeIndex + this.visibleSlides) < this.grades.units.length;
    return {
      'jwl-student-gradebook__action--disabled': !hasNext,
    };
  }

  get hasPreviousSlide (): Record<string, boolean> {
    return {
      'jwl-student-gradebook__action--disabled': this.activeIndex <= 0,
    };
  }

  get filteredUnits (): any[] {
    return this.grades.units.slice(this.activeIndex, this.activeIndex + this.visibleSlides);
  }

  get maxScore (): number {
    let score = 0;
    this.grades.units.forEach((unit: any) => {
      unit.tasks.forEach((task: any) => {
        score += task.maxScore;
      });
    });
    return score;
  }

  get filteredStudents (): GradebookUser[] {
    return this.activeClassroom.students;
  }

  get countAttendance (): number {
    let attendances = 0;

    this.grades?.units?.forEach((unit: any) => {
      unit.tasks?.forEach((task: any) => {
        if (task.type === 'attendance') {
          attendances += 1;
        }
      });
    });

    return attendances;
  }

  mounted (): void {
    this.fetchData();
    window.addEventListener('resize', this.resizeHandler, {
      passive: true,
    });
    this.resizeHandler();
  }

  destroyed (): void {
    this.destroyScrollListener();
    window.removeEventListener('resize', this.resizeHandler);
  }
}
